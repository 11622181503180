import { render, staticRenderFns } from "./parent.vue?vue&type=template&id=5f5fcbb2&scoped=true"
import script from "./parent.vue?vue&type=script&lang=js"
export * from "./parent.vue?vue&type=script&lang=js"
import style0 from "./parent.vue?vue&type=style&index=0&id=5f5fcbb2&prod&lang=scss"
import style1 from "./parent.vue?vue&type=style&index=1&id=5f5fcbb2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f5fcbb2",
  null
  
)

export default component.exports