<template>
  <div class="mb-5">
    <b-row>
      <b-col cols="12">
        <h2 class="text-dark fw-bold-700 mb-3 size24">Detail Produk</h2>
      </b-col>
      <!-- Nama Alias -->
      <b-col cols="9">
        <b-form-group class="custom__form--input" v-if="$route.params.id" label="" label-for="">
          <label for="v-nama-alias" class="mb-25"> Nama Produk </label>
          <b-form-input id="v-nama-alias" :value="(formData && formData.related_product) || formData.alias_name
            " type="text" class="custom__input" readonly />
          <small v-if="
            messages.alias_name &&
            Array.isArray(messages.alias_name) &&
            messages.alias_name.length > 0
          " class="text-error fw-bold-500 size12">{{ messages.alias_name.join(", ") }}</small>
        </b-form-group>
        <b-form-group style="position: relative" v-if="!$route.params.id">
          <div class="d-flex align-items-center justify-content-between mb-25">
            <label class="mb-0">Pilih Produk <span class="text-danger">*</span></label>
            <span class="text-dark">
              <svg class="mr-25" width="17" height="17" viewBox="0 0 17 17" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 4.8125C0 2.43077 1.93077 0.5 4.3125 0.5H11.8125C14.1942 0.5 16.125 2.43077 16.125 4.8125V12.3125C16.125 14.6942 14.1942 16.625 11.8125 16.625H4.3125C1.93077 16.625 0 14.6942 0 12.3125V4.8125ZM6.02214 11.439C6.41266 11.8296 7.04583 11.8296 7.43635 11.439L12.2725 6.60294C12.663 6.21242 12.663 5.57925 12.2725 5.18873C11.8819 4.7982 11.2488 4.7982 10.8582 5.18873L6.72925 9.31772L5.0183 7.60678C4.62778 7.21625 3.99462 7.21625 3.60409 7.60678C3.21357 7.9973 3.21357 8.63047 3.60409 9.02099L6.02214 11.439Z"
                  fill="#45B6AB" />
              </svg>
              Dimiliki
            </span>
          </div>

          <b-form-group class="custom__form--input">
            <b-form-input id="v-nama-alias" v-model="searchProduk" type="text" :class="Array.isArray(messages.alias_name) ? 'error-validation' : ''
              " placeholder="Cari Produk" class="custom__input" @input="changeSearch()" @focus="isFocus = true"
              @blur="blurInput" />
          </b-form-group>

          <div v-if="isFocus" class="rounded mt-1 pt-1" style="
              position: absolute;
              width: 100%;
              background: #eff1f5;
              z-index: 3;
            ">
            <div style="height: 15rem; overflow-y: scroll" v-if="products && products.length > 0">
              <div v-for="(product, index) in products" :key="index">
                <div class="d-flex align-items-center mb-1 px-2 py-25"
                  :class="product.is_default ? 'product__onhover' : ''" :role="product.is_default ? 'button' : ''"
                  @click="
                    product.is_default
                      ? selectProduct(product)
                      : selectOwnedProduct()
                    ">
                  <div class="mr-1" :style="product.is_default ? 'opacity: 0;' : ''">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0 4.8125C0 2.43077 1.93077 0.5 4.3125 0.5H11.8125C14.1942 0.5 16.125 2.43077 16.125 4.8125V12.3125C16.125 14.6942 14.1942 16.625 11.8125 16.625H4.3125C1.93077 16.625 0 14.6942 0 12.3125V4.8125ZM6.02214 11.439C6.41266 11.8296 7.04583 11.8296 7.43635 11.439L12.2725 6.60294C12.663 6.21242 12.663 5.57925 12.2725 5.18873C11.8819 4.7982 11.2488 4.7982 10.8582 5.18873L6.72925 9.31772L5.0183 7.60678C4.62778 7.21625 3.99462 7.21625 3.60409 7.60678C3.21357 7.9973 3.21357 8.63047 3.60409 9.02099L6.02214 11.439Z"
                        fill="#45B6AB" />
                    </svg>
                  </div>
                  <div>
                    <h6 class="mb-0" :class="product.is_default
                      ? 'text-dark'
                      : 'text-secondary cursor-not-allowed'
                      ">
                      {{ product.name }}
                    </h6>
                  </div>
                </div>
              </div>
              <infinite-loading :identifier="infiniteId" @infinite="getData">
                <div slot="spinner">
                  <h6>Load more...</h6>
                </div>
                <div slot="no-more">
                  <h6>Tidak ada lagi list Produk yang tersedia</h6>
                </div>
                <div slot="no-results">
                  <h6>Tidak ada lagi list produk yang tersedia</h6>
                </div>
              </infinite-loading>
            </div>
            <div class="d-flex align-items-center justify-content-center" style="height: 10rem; overflow-y: scroll"
              v-else-if="isLoading">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
            <div class="d-flex align-items-center justify-content-center" style="height: 10rem; overflow-y: scroll"
              v-else-if="products && products.length === 0">
              <h6>Searching...</h6>
            </div>
            <div class="d-flex py-1 border align-items-center justify-content-center bg-white" role="button"
              @click="addProduct">
              <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.25 8.25H8.75V3.75C8.75 3.55109 8.82902 3.36032 8.96967 3.21967C9.11032 3.07902 9.30109 3 9.5 3C9.69891 3 9.88968 3.07902 10.0303 3.21967C10.171 3.36032 10.25 3.55109 10.25 3.75V8.25H14.75C14.9489 8.25 15.1397 8.32902 15.2803 8.46967C15.421 8.61032 15.5 8.80109 15.5 9C15.5 9.19891 15.421 9.38968 15.2803 9.53033C15.1397 9.67098 14.9489 9.75 14.75 9.75H10.25V14.25C10.25 14.4489 10.171 14.6397 10.0303 14.7803C9.88968 14.921 9.69891 15 9.5 15C9.30109 15 9.11032 14.921 8.96967 14.7803C8.82902 14.6397 8.75 14.4489 8.75 14.25V9.75H4.25C4.05109 9.75 3.86032 9.67098 3.71967 9.53033C3.57902 9.38968 3.5 9.19891 3.5 9C3.5 8.80109 3.57902 8.61032 3.71967 8.46967C3.86032 8.32902 4.05109 8.25 4.25 8.25Z"
                  fill="#2B324F" />
              </svg>
              <h6 class="mb-0 text-dark">Tambahkan Produk</h6>
            </div>
          </div>
        </b-form-group>
      </b-col>
      <!-- Satuan -->
      <b-col cols="3">
        <b-form-group>
          <label class="mb-25" for="v-satuan">Satuan <span class="text-danger">*</span></label>
          <v-select v-model="formPayload.uom_uuid" placeholder="Pilih Satuan" label="name" :disabled="disabledUom"
            :class="Array.isArray(messages.uom_uuid) ? 'error-validation' : ''" :options="optionUom"
            @search="onSearchUom" />
          <small v-if="
            messages.uom_uuid &&
            Array.isArray(messages.uom_uuid) &&
            messages.uom_uuid.length > 0
          " class="text-error fw-bold-500 size12">{{ messages.uom_uuid.join(", ") }}</small>
        </b-form-group>
      </b-col>
      <!-- Alias Produk -->
      <b-col v-if="formPayload.product_uuid || $route.params.id" cols="9" class="mb-2">
        <label for="v-nama-alias"> Nama Alias Produk </label>
        <b-form-group class="custom__form--input" label="" label-for="">
          <b-form-input id="v-nama-alias" v-model="formPayload.alias_name" type="text" :class="Array.isArray(messages.alias_name) ? 'error-validation' : ''
            " placeholder="Nama Alias Produk" class="custom__input" />
          <small v-if="
            messages.alias_name &&
            Array.isArray(messages.alias_name) &&
            messages.alias_name.length > 0
          " class="text-error fw-bold-500 size12">{{ messages.alias_name.join(", ") }}</small>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Edit item, brand, and variation -->
    <b-row v-if="$route.params.id" class="mb-2">
      <!-- Brand -->
      <b-col cols="6">
        <b-form-group class="custom__form--input">
          <label for="item_uuid">Brand</label>
          <v-select id="item_uuid" v-model="formPayload.brand_uuid" :options="optionBrands" label="name"
            :reduce="item => item.label" :create-option="item => ({ name: item, label: item })"
            placeholder="Type to search brand..." @search="onSearchBrand">
            <template slot="no-options">
              Type here to search brand...
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <!-- Stok Menipis dan Durasi Stock -->
      <b-col cols="9" class="mb-2">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group class="custom__form--input">
              <b-form-checkbox v-if="$route.params.id" v-model="formPayload.show_on_low_stock" class="mb-1"
                id="low_stock" name="low_stock" value="1">
                Tampilkan saat stok menipis
              </b-form-checkbox>
              <b-form-checkbox v-else v-model="isCreateLowStock" class="mb-1" id="low_stock" name="low_stock" value="1"
                unchecked-value="0">
                Tampilkan saat stok menipis
              </b-form-checkbox>
              <div v-if="
                (!$route.params.id && isCreateLowStock == 1) ||
                ($route.params.id && formPayload.show_on_low_stock == 1) && typeof formPayload.show_on_low_stock !== 'boolean'
              ">
                <label class="mb-25">Qty Stok Minimum <span class="text-danger">*</span></label>
                <b-form-input v-model="formPayload.min_low_stock" type="number" class="custom__input w-100"
                  placeholder="" />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group class="custom__form--input">
              <b-form-checkbox v-model="formPayload.show_on_dead_stock" class="mb-1" id="dead_stock" name="dead_stock"
                value="1" unchecked-value="0">
                Tampilkan produk di laporan Deadstock
              </b-form-checkbox>
              <div v-if="formPayload.show_on_dead_stock == 1">
                <label class="mb-25">Periode (Bulan) <span class="text-danger">*</span></label>
                <b-form-input v-model="formPayload.min_dead_stock" type="number" class="custom__input w-100"
                  placeholder="" />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="">
        <label class="mb-25" for="">Foto Produk (Opsional)</label>
        <br />
        <label for="image" class="upload_photo mb-25"> Unggah Foto </label>
        <b-form-file id="image" class="" accept="image/*" plain hidden @change="changePhoto" />
        <div v-if="photo_url">
          <b-img class="border border-8" width="150" :src="photo_url" />
        </div>
        <br />
        <small class="mb-1"><i>Ukuran file maksimal 2 Mb</i></small>
      </b-col>
    </b-row>

    <b-modal id="modal-add-product-name" hide-header hide-footer centered title="Vertically Centered" ok-only
      ok-title="Accept">
      <div class="p-2 d-flex justify-content-center text-center">
        <h4 class="text-dark font-bold-700 size16 mb-0">Tambah Produk</h4>
      </div>
      <b-container class="my-md-3 my-2">
        <b-row class="p-2" style="gap: 1rem">
          <b-col cols="12">
            <b-form-group class="mb-0" style="position: relative;">
              <label class="mb-0" for="v-nama-produk">Nama Produk</label>
              <b-form-input id="v-nama-produk" v-model="formPayload.alias_name" type="text" placeholder="Nama Produk"
                class="" />
            </b-form-group>
            <!-- <small>Rekomendasi Format: Nama Barang - Nama Brand - Spesifikasi - Satuan</small> -->
          </b-col>
          <!-- Kategori 1 -->
          <b-col cols="12">
            <label for="category_1">Kategori 1<span class="text-danger">*</span></label>
            <v-select id="category_1" v-model="category_1" :options="optionCategory1" label="name"
              placeholder="Type to search category 1..." @search="onSearchCategory1">
              <template slot="no-options">
                Type here to search category 1...
              </template>
            </v-select>
          </b-col>
          <!-- Sub Kategori 2 -->
          <b-col cols="12">
            <label for="sub_category_2">Sub Kategori 1<span class="text-danger">*</span></label>
            <v-select id="sub_category_2" v-model="sub_category1" :options="optionSubCategory1" label="name"
              placeholder="Type to search sub category 2..." @search="onSearchSubCategory1" :disabled="!category_1">
              <template slot="no-options">
                Type here to search sub category 2...
              </template>
            </v-select>
          </b-col>
          <!-- Sub Kategori 3 -->
          <b-col cols="12">
            <label for="sub_category_3">Sub Kategori 2<span class="text-danger">*</span></label>
            <v-select id="sub_category_3" v-model="formPayload.item_uuid" :options="optionSubCategory2" label="name"
              placeholder="Type to search sub category 3..." @search="onSearchSubCategory2" :disabled="!sub_category1"
              :reduce="value => value.uuid">
              <template slot="no-options">
                Type here to search sub category 3...
              </template>
            </v-select>
          </b-col>
          <!-- Brand -->
          <b-col cols="12">
            <div>
              <label class="mb-0" for="brand">Brand:</label>
              <v-select id="brand" v-model="formPayload.brand_uuid" :options="optionBrands" label="name"
                :reduce="item => item.label" :create-option="item => ({ name: item, label: item })"
                placeholder="Type to search brand..." @search="onSearchBrand">
                <template slot="no-options">
                  Type here to search brand...
                </template>
              </v-select>
            </div>
          </b-col>
          <!-- Variation -->
          <b-col cols="12">
            <b-row v-for="(variation, index) in variations" :key="index">
              <b-col cols="5">
                <b-form-group class="rm--input__style" :label="`Variation ${index + 1} Name:`"
                  :label-for="`variation-name-${index}`">
                  <v-select :id="`variation-name-${index}`" v-model="variation.name" label="type"
                    :reduce="variation => variation.type" :options="optionVariations" required />
                </b-form-group>
              </b-col>

              <b-col cols="5">
                <b-form-group class="rm--input__style" :label="`Variation ${index + 1} Value:`"
                  :label-for="`variation-description-${index}`">
                  <v-select :id="`variation-description-${index}`" v-model="variation.values"
                    :options="optionVariations.find(obj => obj.type == variation.name) != undefined ? optionVariations.find(obj => obj.type == variation.name).variations : []"
                    label="name" :reduce="value => value.uuid" required multiple />
                </b-form-group>

              </b-col>

              <b-col class="align-self-center">
                <b-button variant="danger" size="sm" @click="removeVariation(index)">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>

            <!-- <b-button class="mt-1" size="sm" variant="secondary" @click="addVariation">
              Add Variation
            </b-button> -->
          </b-col>
        </b-row>
      </b-container>
      <div class="button__group">
        <b-button id="cancel-modal-add-product" class="button__cancel"
          @click="formPayload.alias_name = '', $bvModal.hide('modal-add-product-name')">
          Batal
        </b-button>
        <b-button id="submit-modal-add-product" class="button__save d-flex align-items-center justify-content-center"
          @click="searchProduk = formPayload.alias_name, $bvModal.hide('modal-add-product-name')">
          <!-- <div v-if="isLoadingSave">
            <b-spinner variant="primary" />
          </div> -->
          Simpan
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import _ from "lodash";
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
  BContainer,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import vSelect from "vue-select";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    vSelect,
    BModal,
    BButton,
    BFormCheckbox,
    BSpinner,
    InfiniteLoading,
  },
  directives: {
    Ripple,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    // eslint-disable-next-line vue/prop-name-casing
    formData: {
      type: Object,
    },
    messages: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    imageUrl: {
      type: String,
    },
    brand: {
      type: "",
    },
    optionsBarang: {
      type: Object,
    },
    optionsSatuan: {
      type: Object,
    },
    optionsBrand: {
      type: Object,
    },
  },
  data() {
    return {
      next_page_url: "",
      page: 1,
      photo: "",
      photo_url: "",
      optionItems: [],
      optionBrands: [],
      optionUom: [],
      products: [],
      formPayload: {
        image: null,
        // item_uuid: '',
        // specification: '',
        uom_uuid: "",
        alias_name: "",
        // sku_code: '',
        show_on_low_stock: 1,
        show_on_dead_stock: 0,
      },
      filter: {},
      isLoading: false,
      selectedBrand: {},
      item: "",
      brandName: "",
      uom: "",
      isFocus: false,
      disabledUom: false,
      searchProduk: "",
      isCreateLowStock: 1,
      infiniteId: +new Date(),
      isCreate: 1,
      optionVariations: [],
      variations: [],
      category_1: null,
      sub_category1: null,
      sub_category2: null,
      optionCategory1: [],
      optionSubCategory1: [],
      optionSubCategory2: []
    };
  },
  watch: {
    'formPayload.brand_uuid': function (value) {
      if (value) {
        if (typeof value === 'object') {
          // this.formPayload.brand_uuid = value.label
          this.getBrandDetail(value.label)
        }

        if (typeof value === 'string') {
          this.brandName = value
        }
      }
    },
    // searchProduk: _.debounce(function () {
    //   this.page = 1;
    //   this.products = [];
    //   this.infiniteId += 1;
    //   this.getData();
    // }, 300),
    searchProduk: _.debounce(function () {
      this.page = 1;
      this.products = [];
      this.infiniteId += 1;
      this.getData();
    }, 300),
    "formPayload.uom_uuid": function (value) {
      if (value) {
        if (typeof value === "object") {
          // this.formPayload.uom_uuid = value.label
          this.getUomDetail(value.label);
        }

        if (typeof value === "string") {
          this.uom = value;
          this.getUomDetail(value);
        }
      }
    },
    'formPayload.item_uuid': function (value) {
      if (value) {
        if (typeof value === 'object') {
          this.getItemsDetail(value.label)
        }

        if (typeof value === 'string') {
          this.uom = value
          this.getItemsDetail(value)
        }
      }
    },
    formData: {
      handler(value) {
        this.searchProduk = value.alias_name;
        this.formPayload = value;
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        this.$store.commit("parentProduct/setProduct", value);
        // this.$emit('payloadProduct', value)
      },
      deep: true,
    },
    imageUrl(value) {
      this.photo_url = value;
    },
    optionsSatuan(value) {
      value.data.forEach((element) => {
        this.optionUom.push({
          label: element.uuid,
          name: element.name,
        });
      });
    },
    variations: {
      handler(variations) {
        this.formPayload.variations = variations
      },
      deep: true,
    },
    category_1: {
      handler(category) {
        this.sub_category1 = null
        if (category) {
          this.getSubCategory1(category)
        } else {
          this.sub_category1 = null
        }
      },
      deep: true,
    },
    sub_category1: {
      handler(category) {
        this.formPayload.item_uuid = null
        if (category) {
          this.getSubCategory2(category)
        } else {
          this.formPayload.item_uuid = null
        }
      },
      deep: true,
    }
  },
  created() {
    this.formPayload = this.$store.state.parentProduct.formPayload;
    this.getData();
  },
  mounted() {
    this.selectedBrand = this.brand
    this.getBrands()
    this.getCategory()
  },
  methods: {
    onSearchSubCategory2(search, loading) {
      if (search.length) {
        loading(true)
        this.searchSubCategory2(loading, search, this)
      }
    },
    searchSubCategory2: _.debounce((loading, search, vm) => {
      vm.$http.get(`/item?parent_uuid=${vm.sub_category1.uuid}&search=${encodeURI(search)}`)
        .then(response => {
          vm.optionSubCategory2 = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    onSearchSubCategory1(search, loading) {
      if (search.length) {
        loading(true)
        this.searchSubCategory1(loading, search, this)
      }
    },
    searchSubCategory1: _.debounce((loading, search, vm) => {
      vm.$http.get(`/item?parent_uuid=${vm.category_1.uuid}&search=${encodeURI(search)}`)
        .then(response => {
          vm.optionSubCategory1 = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    onSearchCategory1(search, loading) {
      if (search.length) {
        loading(true)
        this.searchCategory1(loading, search, this)
      }
    },
    searchCategory1: _.debounce((loading, search, vm) => {
      vm.$http.get(`/item?parent_type=parent&search=${encodeURI(search)}`)
        .then(response => {
          vm.optionCategory1 = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    async getSubCategory2(item) {
      const queryParams = {}
      queryParams.parent_uuid = item.uuid
      this.$store.dispatch('item/getData', { params: queryParams }).then(result => {
        this.optionSubCategory2 = result.data.data.data.map(obj => ({
          uuid: obj.uuid,
          name: obj.name,
        }))
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async getSubCategory1(parent) {
      const queryParams = {}
      queryParams.parent_uuid = parent.uuid
      this.$store.dispatch('item/getData', { params: queryParams }).then(result => {
        this.optionSubCategory1 = result.data.data.data.map(obj => ({
          uuid: obj.uuid,
          name: obj.name,
        }))
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async getCategory(page = 1) {
      this.isLoading = true
      const queryParams = {}
      queryParams.page = page
      queryParams.parent_type = 'parent'

      this.$store.dispatch('item/getData', { params: queryParams }).then(result => {
        this.isLoading = false
        this.result = result.data
        this.optionCategory1 = result.data.data.data.map(obj => ({
          uuid: obj.uuid,
          name: obj.name,
        }))
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    blurInput() {
      if (this.products.length === 0) {
        this.searchProduk = "";
      }
      setTimeout(() => {
        this.isFocus = false;
        // this.products = [];
      }, 300);
    },
    addProduct() {
      // this.disabledUom = false
      // this.formPayload.alias_name = ''
      // this.formPayload.variations = []
      // this.$bvModal.show('modal-add-product-name')
      // delete this.formPayload.product_uuid
      // this.formPayload.uom_uuid = null
      this.disabledUom = false;
      this.formPayload.alias_name = "";
      this.$bvModal.show("modal-add-product-name");
      delete this.formPayload.product_uuid;
      this.formPayload.uom_uuid = {
        label: "",
        name: "",
      };
      this.$emit('showCategoryForm', false);
    },
    selectProduct(product) {
      this.formPayload.alias_name = product.name;
      this.formPayload.product_uuid = product.uuid;
      this.searchProduk = product.name;
      this.formPayload.uom_uuid = {
        label: product.unit_uuid,
        name: product.unit,
      };
      this.disabledUom = product.is_default;
      this.products = [];
      this.$emit('showCategoryForm', true);
    },
    selectOwnedProduct() {
      this.searchProduk = '';
      // this.isSelectedProductFromSearch = false
      // this.$emit('isSelectedProductFromSearch', false)
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    changeSearch() {
      this.page = 1;
      this.products = [];
      this.infiniteId += 1;
    },
    getData($state) {
      this.isLoading = true;
      this.$http
        .get("/cashier/product", {
          uuid: "",
          params: {
            search: this.searchProduk,
            page: this.page, // Increment page number for next page
          },
        })
        .then((result) => {
          if (result.data.data.data.length) {
            this.page += 1;
            this.products.push(...result.data.data.data);
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
          if ($state) {
            $state.complete();
          }
          this.isLoading = false;
        });
    },
    onSearchUom(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUom(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionUom = [];
          this.getUom();
          loading(false);
        }, 500);
      }
    },
    searchUom: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("uom/getData", {
          uuid: "",
          params: {
            search: escape(search),
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.optionUom = [];
          response.data.data.data.forEach((item) => {
            vm.optionUom.push({
              label: item.uuid,
              name: item.name,
            });
          });
          loading(false);
        });
    }, 300),
    onSearchItem(search, loading) {
      if (search.length) {
        loading(true)
        this.searchItem(loading, search, this)
      }
    },
    searchItem: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('item/getData', {
        params: {
          search: encodeURI(search),
        },
      })
        .then(response => {
          vm.optionItems = response.data.data.data.map(item => ({
            label: item.uuid,
            name: item.name,
          }));
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    onSearchBrand(search, loading) {
      if (search.length) {
        loading(true)
        this.searchBrand(loading, search, this)
      }
    },
    searchBrand: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("brand/getData", {
          params: {
            search: escape(search),
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.optionBrands = response.data.data.data.map(item => ({
            label: item.uuid,
            name: item.name,
          }));
        }).catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    async getBrands() {
      this.$store.dispatch('brand/getData', {
        params: '',
      })
        .then(result => {
          this.optionBrands = result.data.data.data.map(item => ({
            label: item.uuid,
            name: item.name,
          }));
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getItems() {
      this.$store.dispatch('item/getData', {
        params: '',
      })
        .then(result => {
          this.optionItems = result.data.data.data.map(item => ({
            label: item.uuid,
            name: item.name,
          }));
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getItemsDetail(id) {
      await this.$store
        .dispatch("item/getDetail", {
          uuid: id || "",
          params: "",
        })
        .then((result) => {
          this.item = result.data.data.name;
          setTimeout(() => {
            this.optionItems.unshift({
              label: result.data.data.uuid,
              title: result.data.data.name,
            });
            const option = this.getUniqueListBy(this.optionItems, "label");
            this.optionItems = option;
          }, 500);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getBrandDetail(id) {
      await this.$store
        .dispatch("brand/getDetail", {
          uuid: id || "",
          params: "",
        })
        .then((result) => {
          this.brandName = result.data.data.name;
          setTimeout(() => {
            this.optionBrands.unshift({ label: result.data.data.uuid, title: result.data.data.name })
            const option = this.getUniqueListBy(this.optionBrands, 'label')
            this.optionBrands = option
          }, 500)
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getUomDetail(id) {
      await this.$store
        .dispatch("uom/getData", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          this.uom = result.data.data.name;
          setTimeout(() => {
            this.optionUom.push({
              label: result.data.data.uuid,
              name: result.data.data.name,
            });
            const option = this.getUniqueListBy(this.optionUom, "label");
            this.optionUom = option;
          }, 500);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getUom() {
      this.$store
        .dispatch("uom/getData", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          this.optionUom = [];
          result.data.data.data.forEach((element) => {
            this.optionUom.push({
              label: element.uuid,
              name: element.name,
            });
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    changePhoto(event) {
      // eslint-disable-next-line prefer-destructuring
      this.formPayload.image = event.target.files[0];
      this.showPhoto(event.target.files[0]);
    },
    showPhoto(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.photo_url = e.target.result;
      };

      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-secondary {
  color: #cccfd3 !important;
}

.box__upload--photo {
  &.error {
    border: 1px dashed #e52a34;

    h6 {
      color: #e52a34 !important;
    }

    small {
      color: #e52a34 !important;
    }

    img {
      filter: brightness(0) saturate(100%) invert(20%) sepia(99%) saturate(3729%) hue-rotate(347deg) brightness(96%) contrast(87%);
    }
  }

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #eff1f5;
  border: 1px dashed #7a7f94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}

.upload_photo {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
  padding: 16px 36.5px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.product__onhover:hover {
  h6 {
    color: #45b6ab !important;
  }
}

.alert-dismissible {
  .close {
    opacity: 1;
    color: #ffffff !important;
  }
}

label {
  margin-bottom: 1rem;
}

.custom__input {
  color: #2b324f !important;
  font-weight: 600;
}

.vs__selected {
  color: #2b324f !important;
  font-weight: 600;
}

.input__sku {
  label {
    margin-bottom: 1rem;
    color: #294f98;
  }

  .custom__input {
    background: #eef2fa !important;
    border: 1px solid #89a7e0 !important;
    border-radius: 8px !important;
    color: #294f98 !important;
    font-weight: 700;
  }
}
</style>
