<template>
  <div class="bg-white">
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col cols="3" class="d-flex align-items-center">
          <div style="cursor: pointer" @click="prevPage">
            <feather-icon size="24" class="text-dark font-weight-bolder" icon="ArrowLeftIcon" />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-center align-items-center">
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            {{ editId ? "Edit" : "Tambah" }} Produk Induk
          </h1>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-button class="px-3" :disabled="disabled || disabledWarehouse || isDisabledBuyingPrice"
            @click="editId ? updateItem() : createItem()">
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <b-row class="mx-0 mt-5 justify-content-center">
        <!-- <b-col
          v-if="!$route.params.id"
          cols="12"
        >
          <b-row class="justify-content-center mb-1 mb-lg-5">
            <b-col cols="6">
              <div class="mb-2">
                <span class="text-dark text-darken-4 fw-bold-600 size14">Langkah {{ step }} dari 2</span>
              </div>
              <b-progress
                :value="progressValue"
                max="100"
                class="progress-bar-success"
                variant="success"
              />
            </b-col>
          </b-row>
        </b-col> -->
        <!-- <keep-alive> -->
        <b-col cols="12" md="8" lg="8">
          <DetailProduk :form-data="formPayload" :messages="messages" :image-url="image_url" :brand="brand"
            :options-barang="batch.items" :options-satuan="batch.uoms" :options-brand="batch.brands"
            @isSelectedProductFromSearch="(value) => isSelectedProductFromSearch = value" @showCategoryForm="showCategoryForm" />

          <div v-if="editId || isSelectedProductFromSearch || isShowCategoryForm">
            <!-- Kategori 1 -->
            <b-col cols="12" md="12" class="mb-2 custom__form--input px-0">
              <label for="category_1">Kategori 1<span class="text-danger">*</span></label>
              <b-form-group>
                <v-select id="category_1" v-model="category_1" :options="optionCategory1" label="name"
                  placeholder="Type to search category 1..." @search="onSearchCategory1">
                  <template slot="no-options">
                    Type here to search category 1...
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <!-- Sub Kategori 2 -->
            <b-col cols="12" md="12" class="mb-2 custom__form--input px-0">
              <label for="sub_category_2">Sub Kategori 2<span class="text-danger">*</span></label>
              <b-form-group>
                <v-select id="category_1" v-model="sub_category1" :options="optionSubCategory1" label="name"
                  placeholder="Type to search sub category 2..." @search="onSearchSubCategory1" :disabled="!category_1">
                  <template slot="no-options">
                    Type here to search sub category 2...
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <!-- Sub Kategori 3 -->
            <b-col cols="12" md="12" class="mb-2 custom__form--input px-0">
              <label for="sub_category_3">Sub Kategori 3<span class="text-danger">*</span></label>
              <b-form-group>
                <v-select id="category_1" v-model="sub_category2" :options="optionSubCategory2" label="name"
                  placeholder="Type to search sub category 3..." @search="onSearchSubCategory2"
                  :disabled="!sub_category1" :reduce="value => value.uuid">
                  <template slot="no-options">
                    Type here to search sub category 3...
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </div>

          <!-- Barcode -->
          <div class="form-group">
            <label for="">Barcode Number:</label>
            <div class="input-group">
              <input v-model="barcode_number" type="text" class="form-control"
                :class="messages.hasOwnProperty('barcode_number') && messages.barcode_number.length > 0 ? 'is-invalid' : ''"
                placeholder="Barcode Number...">
              <div class="input-group-append">
                <button class="btn btn-success" @click="toggleBarcodeScanner()">
                  Scan
                </button>
                <button class="btn btn-primary" @click="generateBarcode()">
                  Generate
                </button>
              </div>
            </div>
            <small v-if="messages.hasOwnProperty('barcode_number') && messages.barcode_number.length > 0"
              class="text-danger">
              {{ messages.barcode_number.join(', ') }}
            </small>
          </div>

          <div class="mb-2">
            <StreamBarcodeReader v-if="showBarcodeScanner" @decode="onDecode" @loaded="onLoaded" />

            <vue-barcode v-if="barcode_number != null && barcode_number != ''" :value="barcode_number"
              element-tag="img">
              Render failed
            </vue-barcode>
          </div>
          <!-- End Barcode -->

          <!-- <IncomingStock v-if="editId" /> -->
          <PriceDiscount :form-data="formPayload" :messages="messages" :price-modal="priceModal" />
        </b-col>
        <b-col cols="12" md="8" lg="8">
          <InitialStock ref="initialStock" :form-data="formPayload" :arr-checked="arr_warehouse" :messages="messages"
            :warehouse-batch="batch.warehouses" @initialStocks="getPayloadInitialStock" />
          <Riwayat :uuid="$route.params.id" v-if="$route.params.id" />
        </b-col>
        <!-- </keep-alive> -->
      </b-row>
    </b-container>
    <!-- <ModalSave
      :type-modal="'induk'"
      :submit-data="createItem"
      :update-item="updateItem"
      :edit-id="editId"
      :is-loading="isLoading"
    /> -->
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BContainer, BProgress, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { mapActions, mapState } from 'vuex'

import { StreamBarcodeReader } from 'vue-barcode-reader'
import VueBarcode from 'vue-barcode'

import DetailProduk from '@/components/MasterProduct/Part/DetailProduk.vue'
import PriceDiscount from '@/components/MasterProduct/Part/PriceDiscount.vue'
import InitialStock from '@/components/MasterProduct/Part/InitialStocks.vue'
import Riwayat from '@/components/MasterProduct/Part/Riwayat.vue'
import ModalSave from '@/components/MasterProduct/Modal/ModalSave.vue'
import vSelect from 'vue-select'
import IncomingStock from "@/components/MasterProduct/Part/IncomingStock.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BContainer,
    DetailProduk,
    PriceDiscount,
    BProgress,
    Riwayat,
    ModalSave,
    InitialStock,
    VueBarcode,
    StreamBarcodeReader,
    BFormGroup, BFormInput,
    vSelect,
    IncomingStock
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  computed: {
    ...mapState({
      prices: (state) => state.parentProduct.discounts,
    }),
  },
  data() {
    return {
      brand: {},
      options: [],
      isDelay: true,
      messages: Object,
      isLoading: false,
      initial_stocks: {
        warehouse_uuid: "",
        note: "",
        stock: 0,
      },
      formPayload: {
        product_type: "parent",
        image: "",
        brand_uuid: "",
        item_uuid: "",
        specification: "",
        uom_uuid: "",
        alias_name: "",
        sku_code: 0,
        standard_price: 0,
        minimum_price: 0,
        buying_price: 0,
        avg_purchase_price: 0,
        discounts: [],
        discount_start_date: "",
        discount_end_date: "",
        initial_stocks: [
          {
            warehouse_uuid: "",
            note: "",
            stock: 0,
          },
        ],
      },
      barcode_number: null,
      image_url: null,
      editId: this.$route.params.id ? this.$route.params.id : null,
      step: this.$store.state.formWizard.step,
      progressValue: 50,
      disabledWarehouse: true,
      disabled: true,
      arr_warehouse: [],
      batch: Object,
      priceModal: 0,
      showBarcodeScanner: false,
      category_1: null,
      sub_category1: null,
      sub_category2: null,
      optionCategory1: [],
      optionSubCategory1: [],
      optionSubCategory2: [],
      isMounted: false,
      isSelectedProductFromSearch: false,
      isDisabledBuyingPrice: true,
      isShowCategoryForm: false
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    "$route.params.id": function (value) {
      this.editId = value;
    },
    "$store.state.formWizard.step": function (value) {
      this.step = value;
      if (this.$route.params.id) {
        this.getDetail();
      }
      if (!this.$route.params.id) {
        this.getPayloadPrice();
        this.getPayloadProduct();
        // this.getPayloadInitialStock()
      }
      this.stepForm();
    },
    "$store.state.parentProduct.product": {
      handler(value) {
        if (value) {
          this.disabledStep1(value)
        }
      },
      deep: true,
    },
    category_1: {
      handler(category) {
        if (this.isMounted) {
          this.sub_category1 = null
          if (category) {
            this.getSubCategory1(category)
          } else {
            this.sub_category1 = null
          }
        } else {
          this.getSubCategory1(category)
        }
      },
      deep: true,
    },
    sub_category1: {
      handler(category) {
        if (this.isMounted) {
          this.sub_category2 = null
          if (category) {
            this.getSubCategory2(category)
          } else {
            this.sub_category2 = null
          }
        } else {
          this.getSubCategory2(category)
        }
      },
      deep: true,
    },
    sub_category2(sub_category2) {
      this.formPayload = {
        ...this.formPayload,
        ...this.$store.state.parentProduct.product,
        item_uuid: sub_category2
      }
    },
    prices: {
      handler(value) {
        if (
          value.standard_price < value.buying_price ||
          value.minimum_price < value.buying_price
        ) {
          this.isDisabledBuyingPrice = true; // Disable the button
        } else {
          this.isDisabledBuyingPrice = false
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.getDetail()
    await this.getBatchRequest()
    await this.getCategory()

    setTimeout(() => {
      this.isMounted = true
    }, 500);
  },
  methods: {
    ...mapActions('parentProduct', ['postData']),
    showCategoryForm(value) {
      this.isShowCategoryForm = value
      console.log(value, 'showCategoryForm');
    },
    onSearchSubCategory2(search, loading) {
      if (search.length) {
        loading(true)
        this.searchSubCategory2(loading, search, this)
      }
    },
    searchSubCategory2: _.debounce((loading, search, vm) => {
      vm.$http.get(`/item?parent_uuid=${vm.sub_category1?.uuid}&search=${encodeURI(search)}`)
        .then(response => {
          vm.optionSubCategory2 = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    onSearchSubCategory1(search, loading) {
      if (search.length) {
        loading(true)
        this.searchSubCategory1(loading, search, this)
      }
    },
    searchSubCategory1: _.debounce((loading, search, vm) => {
      vm.$http.get(`/item?parent_uuid=${vm.category_1.uuid}&search=${encodeURI(search)}`)
        .then(response => {
          vm.optionSubCategory1 = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    onSearchCategory1(search, loading) {
      if (search.length) {
        loading(true)
        this.searchCategory1(loading, search, this)
      }
    },
    searchCategory1: _.debounce((loading, search, vm) => {
      vm.$http.get(`/item?parent_type=parent&search=${encodeURI(search)}`)
        .then(response => {
          vm.optionCategory1 = response.data.data.data.map(obj => ({
            uuid: obj.uuid,
            name: obj.name,
          }))
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    async getSubCategory2(item) {
      const queryParams = {}
      queryParams.parent_uuid = item.uuid
      this.$store.dispatch('item/getData', { params: queryParams }).then(result => {
        this.optionSubCategory2 = result.data.data.data.map(obj => ({
          uuid: obj.uuid,
          name: obj.name,
        }))
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async getSubCategory1(parent) {
      const queryParams = {}
      queryParams.parent_uuid = parent.uuid
      this.$store.dispatch('item/getData', { params: queryParams }).then(result => {
        this.optionSubCategory1 = result.data.data.data.map(obj => ({
          uuid: obj.uuid,
          name: obj.name,
        }))
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async getCategory(page = 1) {
      this.isLoading = true
      const queryParams = {}
      queryParams.page = page
      queryParams.parent_type = 'parent'

      this.$store.dispatch('item/getData', { params: queryParams }).then(result => {
        this.isLoading = false
        this.result = result.data
        this.optionCategory1 = result.data.data.data.map(obj => ({
          uuid: obj.uuid,
          name: obj.name,
        }))
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    toggleBarcodeScanner() {
      this.showBarcodeScanner = !this.showBarcodeScanner
    },
    generateBarcode() {
      this.barcode_number = Math.floor(Math.random() * 90000000000) + 1
      this.formPayload.barcode_number = this.barcode_number
    },
    onDecode(result) {
      this.barcode_number = result
    },
    onLoaded() {
      console.log('Barcode reader loaded')
    },
    async getBatchRequest() {
      const request = [
        {
          url: "/api/warehouse?per_page=50",
          type: "GET",
          request_id: "warehouses",
        },
        {
          url: "/api/item",
          type: "GET",
          request_id: "items",
        },
        {
          url: "/api/uom",
          type: "GET",
          request_id: "uoms",
        },
        {
          url: "/api/brand",
          type: "GET",
          request_id: "brands",
        },
      ];
      const requestId = [
        {
          url: "/api/warehouse?per_page=50",
          type: "GET",
          request_id: "warehouses",
        },
        {
          url: "/api/item",
          type: "GET",
          request_id: "items",
        },
        {
          url: "/api/uom",
          type: "GET",
          request_id: "uoms",
        },
        {
          url: "/api/brand",
          type: "GET",
          request_id: "brands",
        },
      ]
      try {
        const response = await this.$store.dispatch("batch/getData", {
          requests: this.$route.params.id
            ? JSON.stringify(requestId)
            : JSON.stringify(request),
        });
        this.batch = response.data.data;
        this.priceModal = response.data.data?.harga_modal?.modal;
      } catch (error) {
        console.log(error);
      }
    },
    disabledStep1(value) {
      const isUpdate = this.$route.params.id !== undefined;
      let form = {};
      if (value) {
        form = {
          uom_uuid: value?.uom_uuid?.label || '',
          alias_name: value?.alias_name,
        };

        if (!isUpdate) {
          form.min_low_stock = value.min_low_stock || "";
          // form.min_dead_stock = value.min_dead_stock || "";
        }

        // Remove undefined properties
        Object.keys(form).forEach(key => form[key] === undefined && delete form[key]);

        this.disabled = Object.values(form).some(e => e === '');
      }
    },
    nextSubmit() {
      if (!this.$route.params.id) {
        this.$bvModal.show("modal-save");
      }
      if (this.$route.params.id) {
        this.$bvModal.show("modal-save");
      }
    },
    prevPage() {
      switch (this.step) {
        case 1:
          this.brand = {};
          this.$router.go(-1);
          break;
        case 2:
          this.brand = {};
          this.$store.commit("formWizard/setStep", 1);
          break;
        default:
          break;
      }
    },
    stepForm() {
      switch (this.step) {
        case 1:
          this.progressValue = 50;
          break;
        case 2:
          this.progressValue = 100;
          break;
        default:
          break;
      }
    },
    async getDetail() {
      if (this.$route.params.id) {
        await this.$store.dispatch('parentProduct/getData', {
          params: '',
          uuid: this.$route.params.id,
        }).then(result => {
          const {
            uom, alias_name, related_product, standard_price, minimum_price,
            avg_purchase_price, discounts, discount_start_date, discount_end_date,
            buying_price, latest_order_price, stock_in_warehouse, show_on_low_stock,
            min_low_stock, variations, image_url, brand, item, barcode_number, show_on_dead_stock
          } = result.data.data;
          let uom_uuid = null
          let brand_uuid = null
          let item_uuid = null

          if (uom) {
            uom_uuid = {
              label: uom?.uuid || '',
              name: uom?.name || '',
            }
          }
          if (brand) {
            brand_uuid = {
              label: brand?.uuid || '',
              name: brand?.name || '',
            }
          }
          if (item) {
            item_uuid = {
              uuid: item?.uuid || '',
              name: item?.name || '',
            }
            this.sub_category1 = {
              uuid: item?.parent?.uuid || '',
              name: item?.parent?.name || '',
            }
            this.category_1 = {
              uuid: item?.parent?.parent?.uuid || '',
              name: item?.parent?.parent?.name || '',
            }
          }

          this.formPayload = {
            product_type: 'parent',
            uom_uuid: uom_uuid || null,
            brand_uuid: brand_uuid || null,
            item_uuid: item_uuid || null,
            alias_name: alias_name,
            related_product: related_product?.name,
            standard_price: standard_price,
            minimum_price: minimum_price,
            avg_purchase_price: avg_purchase_price,
            discounts: discounts,
            discount_start_date: discount_start_date,
            discount_end_date: discount_end_date,
            buying_price: buying_price,
            latest_order_price: latest_order_price,
            initial_stocks: stock_in_warehouse,
            show_on_low_stock: show_on_low_stock ? 1 : 0,
            show_on_dead_stock: show_on_dead_stock ? 1 : 0,
            min_low_stock: min_low_stock,
            // variations: variations,
            barcode_number: barcode_number,
            min_dead_stock: result.data.data.min_dead_stock,
          };
          this.$store.commit('parentProduct/setProduct', this.formPayload)
          this.sub_category2 = item_uuid || null
          this.arr_warehouse = stock_in_warehouse.map(e => e.uuid);
          this.image_url = image_url;
          this.brand = brand || '';
          this.barcode_number = barcode_number

        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
      }
    },
    getPayloadPrice(value) {
      this.formPayload = value;
    },
    getPayloadProduct(value) {
      this.formPayload = value;
    },
    getPayloadInitialStock(value) {
      this.disabledWarehouse = value.every((e) => !e.warehouse_uuid);
    },
    clearItem() {
      localStorage.removeItem('uom_uuid')
      this.$store.commit('parentProduct/setEditId', null)
      this.$store.commit('parentProduct/setImageUrl', null)
      this.$store.commit('parentProduct/setFormPayload', this.formPayload = {
        product_type: '',
        image: '',
        item_uuid: '',
        specification: '',
        uom_uuid: '',
        alias_name: '',
        sku_code: 0,
        standard_price: 0,
        minimum_price: 0,
        discounts: [],
        discount_start_date: '',
        discount_end_date: '',
        initial_stocks: [],
      })
      this.barcode_number = null
    },
    createItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apa Anda yakin ingin membuat produk induk baru?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          localStorage.setItem(
            "uom_uuid",
            JSON.stringify(this.$store.state.parentProduct.product.uom_uuid)
          );
          const form = this.preparePayload();

          this.isLoading = true;
          this.$store
            .dispatch("parentProduct/postData", {
              query: "",
              payload: form,
            })
            .then(() => {
              successNotification(this, "Success", "Produk berhasil di buat");
              this.clearItem();
              this.isLoading = false;
              this.$router.push({
                name: "master-product.index",
                query: { page: this.$route.query.page || 1 },
              });
              this.$store.commit("formWizard/setStep", 1);
            })
            .catch((error) => {
              this.formPayload.uom_uuid = JSON.parse(
                localStorage.getItem("uom_uuid")
              );
              this.isLoading = false;
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages;
                // errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            });
        }
      });
    },
    updateItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apa Anda yakin ingin mengubah data produk induk ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          localStorage.setItem(
            "uom_uuid",
            JSON.stringify(this.$store.state.parentProduct.product.uom_uuid)
          );
          this.formPayload.product_type = "parent";
          if (this.$route.params.id) {
            delete this.formPayload.initial_stocks;
          }
          const form = this.preparePayload();
          this.isLoading = true;
          form.append("_method", "PATCH");

          this.$store
            .dispatch("parentProduct/postData", {
              query: `/${this.editId}`,
              payload: form,
            })
            .then(() => {
              this.$store.commit("formWizard/setStep", 1);
              successNotification(this, "Success", "Produk berhasil di ubah!");
              this.clearItem();
              this.isLoading = false;
              this.$router.push({
                name: "master-product.index",
                query: { page: this.$route.query.page || 1 },
              });
              // this.$bvModal.hide('modal-add-product-parent')
              this.$store.commit("formWizard/setStep", 1);
            })
            .catch((error) => {
              this.formPayload.uom_uuid = JSON.parse(
                localStorage.getItem("uom_uuid")
              );
              this.isLoading = false;
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages;
                // errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            });
        }
      });
    },
    preparePayload() {
      const isUpdate = this.$route.params.id !== undefined;
      const { product, discounts, initial_stocks } = this.$store.state.parentProduct;

      this.formPayload = {
        product_type: 'parent',
        image: product.image,
        product_uuid: product.product_uuid,
        brand_uuid: product.brand_uuid,
        item_uuid: product.item_uuid,
        uom_uuid: product.uom_uuid.label || product.uom_uuid || '',
        alias_name: product.alias_name,
        standard_price: discounts.standard_price,
        minimum_price: discounts.minimum_price,
        avg_purchase_price: discounts.avg_purchase_price,
        discounts: discounts.discounts,
        discount_start_date: discounts.discount_start_date,
        discount_end_date: discounts.discount_end_date,
        buying_price: discounts.buying_price,
        initial_stocks: initial_stocks,
        show_on_low_stock: isUpdate ? product.show_on_low_stock : 1,
        show_on_dead_stock: isUpdate ? product.show_on_dead_stock : 1,
        min_low_stock: product.min_low_stock,
        // variations: product.variations,
        barcode_number: this.barcode_number,
        min_dead_stock: product.min_dead_stock
      };

      // this.formPayload.initial_stocks = this.$store.state.parentProduct.initial_stocks
      // this.formPayload.product_type = 'parent'
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null && this.formPayload[key] != '') {
          if (key !== 'discounts' && key !== 'initial_stocks' && key !== 'brand_uuid' && key !== 'item_uuid') {
            form.append(key, this.formPayload[key])
          }
          if (key === 'brand_uuid') {
            const uuid = typeof this.formPayload[key] === 'object' ? this.formPayload[key].label : this.formPayload[key]
            form.append(key, uuid)
          }
          if (key === 'item_uuid') {
            const uuid = typeof this.formPayload[key] === 'object' ? this.formPayload[key].uuid : this.formPayload[key]
            form.append(key, uuid)
          }
        }
      }

      // Assign variation uuid from values to single array
      // this.formPayload.variations?.forEach(variation => {
      //   variation.values.forEach(value => {
      //     form.append('variations[]', value)
      //   })
      // })

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.formPayload.discounts.length; index++) {
        const element = this.formPayload.discounts[index];
        // eslint-disable-next-line no-restricted-syntax
        for (const key in element) {
          if (Object.hasOwnProperty.call(element, key)) {
            // eslint-disable-next-line no-use-before-define
            const items = element[key];
            form.append(`discounts[${index}][${key}]`, items);
          }
        }
      }
      if (this.formPayload.initial_stocks) {
        // eslint-disable-next-line no-plusplus
        for (
          let index = 0;
          index < this.formPayload.initial_stocks[0].length;
          index++
        ) {
          const element = this.formPayload.initial_stocks[0][index];
          if (
            element.warehouse_uuid !== "" &&
            element.warehouse_uuid !== false &&
            element.warehouse_uuid !== true &&
            element.warehouse_uuid !== undefined
          ) {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in element) {
              if (Object.hasOwnProperty.call(element, key)) {
                // eslint-disable-next-line no-use-before-define
                const items = element[key];
                if (key !== "stock" && key !== "note") {
                  form.append(`initial_stocks[${index}][${key}]`, items);
                }
              }
            }
          }
        }
      }
      return form;
    },
    directPage() {
      this.clearItem();
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

body {
  background-color: #fff !important;
}

.bg-dark {
  background: $dark !important;
}

.custom__input {
  &.error-validation {
    background: #feefef;
    border: 1px solid #f48989;

    .input-group-text {
      background-color: #feefef !important;
      border: 1px solid #f48989 !important;
    }
  }
}

.error-validation {
  .input-group-text {
    background-color: #feefef !important;
    border: 1px solid #feefef !important;
  }
}

.v-select {
  &.error-validation {
    .vs__dropdown-toggle {
      background: #feefef !important;
      border: 1px solid #f48989 !important;
    }
  }
}

.progress-bar-success {
  background-color: #EFF1F5;

  .progress-bar {
    background: linear-gradient(269.89deg,
        #6cdfd4 0%,
        #45b6ab 98.81%) !important;
    box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
    border-radius: 20px;
  }
}

label {
  font-size: 14px;
  color: $dark;
  font-weight: 600;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
  height: 52px;
  background: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  // height: 52px;
  // background-color: #EFF1F5;
  // border: 1px solid #E4E5EC;
  border-radius: 8px;
}
</style>
